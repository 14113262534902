@font-face {
  font-family: BaiJamjureeBold;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-Bold.ttf');
}

@font-face {
  font-family: BaiJamjureeBoldItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-BoldItalic.ttf');
}

@font-face {
  font-family: BaiJamjureeExtraLight;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-ExtraLight.ttf');
}

@font-face {
  font-family: BaiJamjureeExtraLightItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-ExtraLightItalic.ttf');
}

@font-face {
  font-family: BaiJamjureeItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-Italic.ttf');
}

@font-face {
  font-family: BaiJamjureeLight;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-Light.ttf');
}

@font-face {
  font-family: BaiJamjureeLightItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-LightItalic.ttf');
}

@font-face {
  font-family: BaiJamjureeMedium;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-Medium.ttf');
}

@font-face {
  font-family: BaiJamjureeMediumItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-MediumItalic.ttf');
}

@font-face {
  font-family: BaiJamjureeRegular;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-Regular.ttf');
}

@font-face {
  font-family: BaiJamjureeSemiBold;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-SemiBold.ttf');
}

@font-face {
  font-family: BaiJamjureeSemiBoldItalic;
  src: url('./asset/font/bai-jamjuree/BaiJamjuree-SemiBoldItalic.ttf');
}

* {
  font-family: BaiJamjureeRegular;
}

body {
  margin: 0;
}

.delete-selection-link {
  color: #979797;
  text-decoration: none;
  display: inline-block;
}

.delete-selection-link:hover {
  color: #979797;
}

.data-table table th {
  color: #91A6B8 !important;
  background-color: white !important;
}

