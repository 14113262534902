.login-layout {
    height: 100vh;
    background-color: rgb(230, 236, 237);
    position: relative;
}

.login-layout .box {
    position: absolute;
    top: 40%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
    height: fit-content;
    padding: 20px;
    border: 3px solid black;
    border-radius: 30px;
}

.login-layout .box .title {
    font-size: 20px;
    border-bottom: 1px solid black;
}

.login-layout .box .body {
    margin-top: 20px;
}

.login-layout .ant-btn-primary {
    background-color: #DC0000;
    color: white;
}

.login-layout .ant-btn-primary:hover {
    background-color: #DC0000 !important;
    color: white !important;
}