.cms-header {
    background-color: white;
    padding: 0 25px 0 20px;
    display: flex;
    justify-content: space-between;
}

.cms-header .button.toggle-menu {
    width: 40px;
    height: 40px;
}

.cms-header .button.toggle-menu .anticon {
    font-size: 20px;
}

.cms-header .title {
    font-weight: bold;
    font-size: 20px;
    margin-left: 170px;
    transition: margin-left 0.2s;
}

.cms-header .title.collapsed {
    margin-left: 50px;
}