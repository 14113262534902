.admin-license-edit-form input:disabled {
    font-weight: bold;
}

.license-table {
    margin-bottom: 25px;
    display: block;
}

.license-table td {
    min-width: 150px;
    margin: 0;
}

.license-table .table-header{
    color: #91A6B8;
    padding-bottom: 20px;
}

.license-table th div, .license-table td div {
    margin: 0;
    padding: 0;
    width: 100%;
}

.license-table input[type="checkbox"]:disabled {
    cursor: not-allowed;
}