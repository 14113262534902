.module-table {
    margin-bottom: 25px;
    display: block;
}

.module-table th, .module-table td {
    border: 1px solid black;
    text-align: center;
    min-width: 100px;
    margin: 0;
    padding: 0;
}

.module-table .addmodulePartPropertySpan {
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin: 0 15px 0 15px;
}

.module-table .addmodulePartSpan {
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.module-table .addmodulePartPropertySpan span, .module-table .addmodulePartSpan span  {
    font-size: 22px;
}

.module-table th div, .module-table td div {
    margin: 0;
    padding: 0;
    width: 100%;
}

.module-table th input, .module-table td input {
    font-weight: bold;
    border: none;
    text-align: center;
    width: 100%;
}

.module-table th input:focus, .module-table td input:focus {
    border: 1px solid black;
    border-radius: 0;
}

.module-table .delete-button {
    color: red;
    cursor: pointer;
}