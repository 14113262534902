.company-admin-user-list .user-table-row .status {
    width: 70px;
    height: 20px;
    border-radius: 20px;
    display: inline-block;
}

.company-admin-user-list .user-table-row .status.invalid {
    background-color: #DC0000;
}

.company-admin-user-list .user-table-row .status.valid {
    background-color: #00E39D;
}