.sider-menu {
    background-color: #DC0000;
}

.sider-menu .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #DC0000;
}

.sider-menu .ant-menu {
    background-color: #DC0000;
}

.sider-menu .ant-menu-item {
    color: white;
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: #DC0000 !important;
    color: white !important;
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-item-selected {
    background-color: #F4F7F9 !important;
    color: #DC0000 !important;
}

.sider-menu .ant-menu-item.ant-menu-item-selected {
    background-color: #F3F7F9;
    color: #DC0000;
}

.sider-menu .ant-menu .ant-menu-sub {
    background-color: #DC0000 !important;
}

.sider-menu .ant-menu-submenu-title {
    color: white;
}

.holofire-text {
    font-size: 23px;
    color: white;
    padding: 0 0 15px 0;
    text-transform: uppercase;
}

.nav-link {
    text-decoration: none;
}

.user-settings-popover {
    padding: 10px;
    font-size: 16px;
}

.user-settings-popover .profile {
    display: block;
    text-decoration: none;
}

.sider-menu .button {
    color: white;
    margin-bottom:10px;
}

.sider-menu .button .anticon{
    color: white;
    font-size: 20px;
}

.sider-menu .button:hover {
    color: white;
}