.default-layout {
    min-height: 100vh;
}

.default-layout .content {
    padding: 5px 25px 25px 25px;
    background-color: #F3F7F9;
}

.default-layout .ant-btn-primary {
    background-color: #DC0000;
    color: white;
}

.default-layout .ant-btn-primary:hover {
    background-color: #DC0000 !important;
    color: white !important;
}