.instructor-education-table {
    margin-bottom: 25px;
    display: block;
}

.instructor-education-table td {
    min-width: 150px;
    margin: 0;
}

.instructor-education-table .table-header{
    color: #91A6B8;
    padding-bottom: 20px;
}

.instructor-education-table th div, .instructor-education-table td div {
    margin: 0;
    padding: 0;
    width: 100%;
}

.instructor-education-table input[type="checkbox"]:disabled {
    cursor: not-allowed;
}